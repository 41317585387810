<template>
  <div class="vipbox">
    <CompHeader title="每日奖励" />
    <div class="listbox">
      <div class="titletips">
        <img
          src="../../assets/newImages/notice-icon.png"
          alt=""
        />每日奖励每24小时刷新
      </div>
      <div class="boxlist">
        <div class="items" v-for="item in boxlist" :key="item.id">
          <div class="itemtitle">
            {{ item.name }}
          </div>
          <div class="imgbox" v-if="item.vip_level == vipdata?.user?.vip_level">
            <img :src="item.box.cover" alt="" />
          </div>
          <div class="imgbox" v-else>
            <img class="gimg" :src="item.box.cover" alt="" />
          </div>
          <div
            class="bottombox3"
            @click="enteropen"
            v-if="
              isButtonDisabled && item.vip_level == vipdata?.user?.vip_level
            "
          >
            <img src="../../assets/newImages/time.gif" alt="" />
            <div>{{ countdown }}</div>
          </div>
          <div
            class="bottombox2"
            v-else-if="item.vip_level == vipdata?.user?.vip_level"
            @click="govipopen(item.box_id, item.id, 1)"
          >
            打开
          </div>

          <div
            class="bottombox"
            v-else
            @click="govipopen(item.box_id, item.id, 2)"
          >
            当前等级不满足开箱条件
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Welfare, WelfareOpen, getuservipdataApi } from "../../network/api";
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      boxlist: [],
      vipdata: {},

      isopenbox: false,
      luckdata: {},
      chooseList: [],

      lastApiCall: this.getLastApiCall(), // 从 localStorage 获取上次调用时间
      isButtonDisabled: false, // 按钮是否禁用
      countdown: "", // 倒计时字符串
      timer: null, // 定时器
    };
  },
  created() {
    this.getvipdata();
  },
  mounted() {
    this.checkButtonState(); // 组件挂载后检查按钮状态
  },
  beforeDestroy() {
    // 组件销毁前清理定时器
    clearInterval(this.timer);
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    startCountdown() {
      // 获取当前时间
      const now = new Date();
      const nextCall = new Date(); // 创建一个新的日期对象
      nextCall.setDate(now.getDate() + 1); // 设置为明天
      nextCall.setHours(0, 0, 0, 0); // 设置时间为0点

      // 每秒更新倒计时
      this.timer = setInterval(() => {
        const remainingTime = nextCall - new Date(); // 计算剩余时间
        this.updateCountdown(remainingTime);
      }, 1000);
    },
    updateCountdown(remainingTime) {
      if (remainingTime <= 0) {
        clearInterval(this.timer);
        this.isButtonDisabled = false; // 启用按钮
        this.countdown = "";
        localStorage.removeItem("lastApiCall"); // 清除存储的时间
      } else {
        const hours = Math.floor((remainingTime / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((remainingTime / (1000 * 60)) % 60);
        const seconds = Math.floor((remainingTime / 1000) % 60);
        this.countdown = `${hours}:${minutes}:${seconds}`;
      }
    },
    getLastApiCall() {
      // 从 localStorage 获取上次调用时间
      const lastApiCall = localStorage.getItem("lastApiCall");
      return lastApiCall ? new Date(lastApiCall) : null;
    },
    checkButtonState() {
      if (this.lastApiCall) {
        const lastDate = new Date(this.lastApiCall);
        const now = new Date();
        const todayStart = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          0,
          0,
          0,
          0
        );
        const todayEnd = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate() + 1,
          0,
          0,
          0,
          0
        );
        const today = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate()
        );
        console.log(lastDate < todayStart, "是否昨日", todayStart, lastDate);
        if (lastDate < todayStart) {
          // lastApiCall是今天的零点之前的日期，清除封禁状态
          clearInterval(this.timer);
          this.isButtonDisabled = false; // 启用按钮
          this.countdown = "";
          localStorage.removeItem("lastApiCall"); // 清除存储的时间
        } else if (lastDate < todayEnd) {
          // lastApiCall在今天之内
          const nextCall = new Date(today);
          nextCall.setDate(today.getDate() + 1);
          nextCall.setHours(0, 0, 0, 0);
          this.isButtonDisabled = now < nextCall;
          if (this.isButtonDisabled) {
            const remainingTime = nextCall - now;
            this.updateCountdown(remainingTime);
            this.startCountdown();
          }
        }
      }
    },
    enteropen() {
      this.$message.warning("今日箱子已开启");
    },
    govipopen(id, v, i) {
      this.$router.push({
        path: "/Vipopenbox",
        query: {
          id: id,
          openid: v,
          isopentype: i,
        },
      });
    },
    async getvipdata() {
      let params = {
        type: 5,
      };
      const res = await Welfare(params);
      this.boxlist = res.data.data;
      const vip = await getuservipdataApi();
      if (vip.data.code == 200) {
        this.vipdata = vip.data.data;
      } else {
        this.$message.error(vip.data.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.vipbox {
  overflow: hidden;
  padding-top: 0.6rem;
  .listbox {
    width: 3.35rem;
    margin: 0 auto;
    .titletips {
      width: 100%;
      display: flex;
      font-weight: 400;
      font-size: 0.1rem;
      color: #baa272;
      img {
        width: 0.1rem;
        height: 0.1rem;
        margin-right: 0.12rem;
      }
    }
    .boxlist {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 0.14rem;
      .items {
        width: 1.6rem;
        height: 1.8rem;
        @include bgImg("vip-itemsbg");
        .itemtitle {
          width: 100%;
          height: 0.3rem;
          line-height: 0.3rem;
          text-align: center;
          font-family: YouSheBiaoTiYuan, YouSheBiaoTiYuan;
          font-weight: 500;
          font-size: 0.16rem;
          color: #ffedd1;
        }
        .imgbox {
          width: 0.9rem;
          height: 1.1rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 auto;
          img {
            width: 100%;
          }
          .gimg {
            filter: grayscale(100%);
          }
        }
        .bottombox {
          width: 100%;
          height: 0.3rem;
          @include bgImg("end-bg");
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          font-size: 0.14rem;
          color: #fae277;
        }
        .bottombox2 {
          width: 100%;
          height: 0.3rem;
          @include bgImg("join-bg");
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          font-size: 0.14rem;
          color: #fae277;
        }
        .bottombox3 {
          width: 100%;
          height: 0.3rem;
          @include bgImg("vip-noclickbg");
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          font-size: 0.14rem;
          color: #fae277;
          img {
            width: 0.14rem;
            height: 0.14rem;
            margin-right: 0.08rem;
          }
        }
      }
    }
  }
  .showbox {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 20%;
    left: 50%;
    .a-open-box-new {
      width: 100%;
      padding: 0.12rem 0.12rem 0.33rem 0.12rem;
      display: flex;
      align-content: space-between;
      justify-content: center;
      flex-wrap: wrap;
      transform: translate(-50%, -50%);
      .title-box {
        width: 110%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 0.2rem;
        font-weight: 500;
        font-size: 0.18rem;
        color: #ffffff;
        margin-bottom: 0.12rem;
        position: absolute;
        z-index: 100;
        left: -0.19rem;
        top: 0;
      }
      .getSome-box {
        width: 110%;
        position: absolute;
        z-index: 100;
        left: -0.19rem;
        top: 0.62rem;
        padding: 0 0.32rem;
      }
      .a-show-box {
        width: 100%;
        display: flex;
        align-items: center;
        // justify-content: center;
        justify-content: space-evenly;
        // height: 2.2rem;
        margin-bottom: 0.28rem;
        flex-wrap: wrap;

        .img-item {
          width: 1.41rem;
          height: 1.41rem;
          padding: 0.07rem 0.08rem;
          margin: 0 0.02rem;
          margin-bottom: 0.04rem;
          border: 0.02rem solid rgba(218, 174, 56, 0);
          .alias {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.1rem;
            font-weight: 100;
          }
          .top-img-box {
            // width: 100%;
            // height: 0.5rem;
            width: 1.28rem;
            height: 0.86rem;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .name {
            margin-top: -0.06rem;
            font-size: 0.1rem;
            // max-width: 2rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding: 0 0.08rem;
            text-align: center;
            margin-bottom: 0.04rem;
          }

          .price {
            // margin-top: 0.04rem;
            font-size: 0.1rem;
            // margin-bottom: 0.02rem;
          }
        }
        .active-bg {
          // border: 0.02rem solid rgba(218, 174, 56, 0.5);
        }

        .bg-1 {
          background: url("../../assets/newImages/open-bg1.png") no-repeat;
          background-size: 120% 120%;
          background-position: -0.1rem 0rem;
          z-index: 99;
        }

        .bg-2 {
          background: url("../../assets/newImages/open-bg2.png") no-repeat;
          background-size: 120% 120%;
          background-position: -0.1rem 0rem;
          z-index: 99;
        }

        .bg-3 {
          background: url("../../assets/newImages/open-bg3.png") no-repeat;
          background-size: 120% 120%;
          background-position: -0.1rem 0rem;
          z-index: 99;
        }

        .bg-4 {
          background: url("../../assets/newImages/open-bg4.png") no-repeat;
          background-size: 120% 120%;
          background-position: -0.1rem 0rem;
          z-index: 99;
        }
      }

      .bottom-box {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0.2rem;
        .cancel {
          width: 1.12rem;
          height: 0.3rem;
          background: url("../../assets/newImages/user-itembg.png") no-repeat;
          background-size: 100% 100%;
          font-size: 0.1rem;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-left: 0;
          padding: 0.12rem;
        }

        .confirm {
          width: 1.12rem;
          height: 0.3rem;
          background: url("../../assets/newImages/user-itembg.png") no-repeat;
          background-size: 100% 100%;
          display: flex;
          font-size: 0.1rem;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
</style>
